import { Box, Button, InputAdornment, Typography, OutlinedInput } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import useAuth from "../../../hooks/useAuth";

type Props = {
	onClose: () => void;
	setStep: Dispatch<SetStateAction<"mobile-verify" | "otp-verify">>;
};

const MobileVerification: React.FC<Props> = ({ onClose, setStep }) => {
	const { handleModels, setHandleModels, handleSendLoginOtp } = useAuth();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setHandleModels({ ...handleModels, state: { ...handleModels.state, [name]: value } });
	};

	return (
		<>
			<Box className="btnClose" textAlign={"right"}>
				<Close onClick={onClose} />
			</Box>
			<Typography variant="h3" mb={1}>
				Login
			</Typography>
			<Typography textAlign={"left"} className="textPrimary" fontWeight={700} mb={1.5} ml={4} mt={12}>
				Mobile Number
			</Typography>
			<OutlinedInput
				sx={{ width: "88%" }}
				id="outlined-adornment-weight"
				type="number"
				name="mobile"
				value={handleModels?.state?.mobile}
				startAdornment={<InputAdornment position="start">{handleModels.state.countryCode}</InputAdornment>}
				aria-describedby="outlined-weight-helper-text"
				placeholder="Enter your number"
				onChange={handleChange}
			/>
			<Button
				variant="contained"
				className="btnPrimary"
				sx={{ width: "88%", mt: 3 }}
				onClick={() => handleSendLoginOtp(setStep)}
				disabled={!(handleModels?.state?.mobile?.length >= 10)}
			>
				Continue
			</Button>
		</>
	);
};

export default MobileVerification;
