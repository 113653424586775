import { Modal } from '@mui/material';
import styled from 'styled-components';

export const CallModelStyle = styled(Modal)`
    .btnClose{
        img{
            margin: 15px 0 0 15px;
            cursor: pointer;
        }
    }
    h1{
        color: #191D32;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
    }
    button.btnPrimary.btnUpdate{
        @media(max-width: 680px){
            width: 95%;
        }
    }
    .create-call-data{
        .MuiOutlinedInput-root{
            background: #F0F6F9;
            color: #929AA3;
            font-weight: 500;
            font-size: 14px;
            border-radius: 8px;
            height: 64px;
            .MuiInputBase-input{
                padding-left: 27px;
                ::placeholder{
                    opacity: 1;
                }
            }
            svg{
                fill: #929AA3;
            }
            fieldset{
                border-color: #C5DFEB;
            }
        }
        .MuiFormHelperText-root.Mui-error{
            font-size: 14px;
        }
        .err-mess{
            color: #d32f2f;
            font-size: 14px;
            margin: 3px 14px 0 14px;
        }
        .dateField{
            .MuiStack-root{
                padding-top: 0;
            }
            .MuiFormControl-root{
                width: 100%;
                .MuiIconButton-root{
                    margin-right: 0;
                }
            }
        }
    }
    .viewCallDesc{
        @media(max-width: 680px){
            width: 95%;
        }
        h5{
            font-weight: 500;
            font-size: 16px;
            color: #3846CE;
            line-height: 26px;
        }
        .stock-result{
            background-color: #f6f6fc;
            &:nth-child(even){
                background-color: #feffff;
            }
            h6{
                color: #929AA3;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
            }
            p{
                color: #191D32;
                font-weight: 300;
                line-height: 26px;
            }
        }
    }
    .viewAllDesc{
        .googleInc{
            background: #FFFFFF;
            border-radius: 12px;
            padding: 18px 22px 18px 18px;
            h4{
                color: #191D32;
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
            }
            p{
                color: #929AA3;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
            }
            button.textPrimary{
                font-size: 14px;
                text-transform: unset;
                font-weight: 400;
                padding: 0;
                :hover{
                    background-color: transparent;
                }
            }
        }
    }
`;