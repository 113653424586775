import React, { createContext } from "react";
import { ToastContainer, toast, ToastContainerProps, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface ToastContextValue {
	notify: (message: string, options?: ToastOptions) => void;
}

export const ToastContext = createContext<ToastContextValue>({
	notify: () => {},
});

type ToastProviderProps = Omit<ToastContainerProps, "toast"> & {
	children: React.ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
	const notify = (message: string, options?: ToastOptions) => {
		toast(message, options);
	};

	return (
		<ToastContext.Provider value={{ notify }}>
			{children}
			<ToastContainer autoClose={3000} hideProgressBar={true} closeOnClick={true} />
		</ToastContext.Provider>
	);
};
