import Header from "./header";
import Footer from "./footer";

type Props = {
  children: Element | Element[] | any
};

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Layout;