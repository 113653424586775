import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { FooterStyle } from "./styled";

const Footer = () => {
  const location = useLocation();

  return (
    <FooterStyle style={{ backgroundColor: location.pathname === '/profile' ? '#3846CE' : location.pathname === '/call' ? '#F0F6F9' : '#fff' }}>
      <Typography py={1} textAlign={"center"} fontSize={"12px"} color={"#000"}>©2023, Stocs. All rights reserved.</Typography>
    </FooterStyle>
  );
}

export default Footer;