import { Box, Button, TextField, Typography, MenuItem } from "@mui/material";
import { Close } from "@mui/icons-material";
import backArrow from "../../../assets/images/backArrow.svg";
import { Dispatch, SetStateAction, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";

type Props = {
	onClose: () => void;
	setStep: Dispatch<SetStateAction<"verification" | "upload-profile" | "business-info" | "approval-request">>;
};

const BusinessInfo: React.FC<Props> = ({ onClose, setStep }) => {
	const { handleModels, setHandleModels, handleRegister, tradeTypeInRegister, getTradeTypeInRegister } = useAuth();
	const { businessName, sebiNumber, tradeType } = handleModels.state;

	const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
		setHandleModels((ps) => ({ ...ps, state: { ...ps.state, [name]: value } }));
	};

	useEffect(() => {
		getTradeTypeInRegister();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Box className="btnClose" mb={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
				<img src={backArrow} alt="back" onClick={() => setStep("upload-profile")} />
				<Close onClick={onClose} />
			</Box>
			<Typography variant="h3" mb={1}>
				Business Information
			</Typography>
			<Box>
				<Typography mb={7}>Enter your business information below</Typography>
				<TextField
					sx={{ width: "88%", mb: 2.5 }}
					id="outlined-basic"
					name="businessName"
					value={businessName}
					onChange={handleChange}
					variant="outlined"
					label="Business Name"
				/>
				<TextField
					sx={{ width: "88%", mb: 2.5 }}
					id="outlined-basic"
					name="sebiNumber"
					value={sebiNumber}
					onChange={handleChange}
					variant="outlined"
					label="SEBI Registered Id"
				/>

				<TextField
					sx={{ width: "88%", mb: 2.5 }}
					select
					id="outlined-basic"
					name="tradeType"
					value={tradeType}
					onChange={handleChange}
					variant="outlined"
					label="Trade Types"
				>
					{tradeTypeInRegister.map((item: string, index) => (
						<MenuItem value={item} key={item}>
							{item}
						</MenuItem>
					))}
				</TextField>

				<Button
					variant="contained"
					className="btnPrimary"
					disabled={!businessName || !sebiNumber || !tradeType}
					sx={{ width: "88%", mt: 3 }}
					onClick={() => handleRegister(setStep)}
				>
					Register
				</Button>
			</Box>
		</>
	);
};

export default BusinessInfo;
