import styled from 'styled-components';

export const CallStyle = styled.div`
    padding-top: 118px;
    @media(max-width: 899px){
        padding-top: 106px;
    }
    @media(max-width: 599px){
        padding-top: 90px;
    }
    .chartImg{
        width: 100%;
        max-height: 506px;
        min-height: 300px;
        object-fit: cover;
    }
    .callContent{
        max-width: 1650px;
        h3{
            font-weight: 700;
            font-size: 32px;
            line-height: 42px;
        }
        button{
            font-weight: 400;
            padding: 0;
            :hover{
                background-color: transparent;
            }
        }
        button.textSecondary{
            font-size: 16px;
            text-transform: capitalize;
        }
        button.textPrimary{
            font-size: 14px;
            text-transform: unset;
        }
        .googleInc{
            background: #FFFFFF;
            border-radius: 12px;
            padding: 18px 22px 18px 18px;
            h4{
                color: #191D32;
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
            }
            p{
                color: #929AA3;
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
            }
        }
        .stockBox{
            background: #3846CE;
            border-radius: 12px;
            min-height: 437px;
            @media(max-width: 1340px){
                padding: 40px;
            }
            @media(max-width: 899px){
                min-height: initial;
            }
            h2{
                font-weight: 700;
                font-size: 32px;
                color: #F5B544;
                line-height: 42px;
            }
            h5{
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 21px;
            }
        }
        .MuiOutlinedInput-root{
            background: #F0F6F9;
            color: #929AA3;
            font-weight: 500;
            font-size: 14px;
            border-radius: 8px;
            height: 64px;
            .MuiInputBase-input{
                padding-left: 27px;
                ::placeholder{
                    opacity: 1;
                }
            }
            svg{
                fill: #929AA3;
            }
            fieldset{
                border-color: #C5DFEB;
            }
        }
        .dateField{
            .MuiStack-root{
                padding-top: 0;
            } 
            .MuiFormControl-root{
                width: 100%;
                .MuiIconButton-root{
                    margin-right: 0;
                }
            }
        }
        button.btnPrimary.btnApply{
            color: #F0F6F9 !important;
            font-size: 18px;
            padding: 16px 28px;
        }
    }
`;