import { FC, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
	AppBar,
	Box,
	Button,
	Container,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Toolbar,
	Typography,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { HeaderStyle } from "./styled";
import { initHandleModels } from "../../context/AuthContext";
interface Props {}

const drawerWidth = 240;

const Header: FC<Props> = (props) => {
	const { isLogin, setHandleModels, handleLogout } = useAuth();
	const location = useLocation();
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const drawer = (
		<Box className="mobileMenu">
			<Box className="btnClose" textAlign={"right"}>
				<Close onClick={() => setMobileOpen(false)} />
			</Box>
			<List>
				<ListItem disablePadding>
					<ListItemButton
						component="a"
						href={"/"}
						className={location.pathname === "/" ? "textPrimary" : "textSecondary"}
					>
						<ListItemText primary={"Home"} />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						component="a"
						href={"/call"}
						className={location.pathname === "/call" ? "textPrimary" : "textSecondary"}
					>
						<ListItemText primary={"Call"} />
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						component="a"
						href={"/profile"}
						className={location.pathname === "/profile" ? "textPrimary" : "textSecondary"}
					>
						<ListItemText primary={"Profile"} />
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	const container = document !== undefined ? () => document.body : undefined;

	return (
		<HeaderStyle>
			<AppBar component="nav">
				<Container>
					<Toolbar>
						<IconButton
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							className="menuIcon"
							sx={{ mr: { xs: 1, sm: 2 }, display: { sm: "none" }, color: "#3846CE" }}
						>
							<Menu />
						</IconButton>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							<Link to={"/"} className="textPrimary">
							Stocs
							</Link>
						</Typography>
						{!isLogin ? (
							<>
								{/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
									<Link to={"/"} className={location.pathname === "/" ? "textPrimary" : "textSecondary"}>
										Home
									</Link>
								</Box> */}
								<Button
									variant="contained"
									className="btnPrimary"
									onClick={() => setHandleModels({ ...initHandleModels, for: "login" })}
								>
									Login
								</Button>
							</>
						) : (
							<>
								{/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
										{navItems.map((item) => (
											<Link
												to={item.href}
												key={item.title}
												className={location.pathname === item.href ? "textPrimary" : "textSecondary"}
											>
												{item.title}
											</Link>
										))}
									</Box> */}
								<Button variant="contained" className="btnPrimary" onClick={handleLogout}>
									Logout
								</Button>
							</>
						)}
					</Toolbar>
				</Container>
			</AppBar>
			<Box component="nav">
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
			</Box>
		</HeaderStyle>
	);
};

export default Header;
