import styled from "styled-components";

export const ProfileStyle = styled.div`
	background: #3846ce;
	color: #fff;
	.profileContent {
		padding-top: 118px;
		max-width: 1460px;
		@media (max-width: 899px) {
			padding-top: 106px;
		}
		@media (max-width: 599px) {
			padding-top: 90px;
		}
		p {
			font-weight: 300;
			font-size: 22px;
			line-height: 36px;
			color: #c6e1ff;
		}
		h2 {
			font-weight: 700;
			font-size: 34px;
			line-height: 44px;
			max-width: 42%;
			align-items: flex-end;
			@media (max-width: 767px) {
				font-size: 28px;
			}
			span {
				color: #c5d4ff;
				font-weight: 400;
				font-size: 17px;
				white-space: nowrap;
			}
			svg {
				fill: #f5b544;
				font-size: 33px;
				margin: 0 3px 5px 12px;
			}
		}
		h6 {
			font-weight: 400;
			font-size: 19px;
			line-height: 32px;
			color: #c6e1ff;
			@media (max-width: 767px) {
				font-size: 17px;
			}
		}
		h3 {
			font-weight: 700;
			font-size: 56px;
			line-height: 74px;
			@media (max-width: 767px) {
				font-size: 44px;
			}
			span {
				font-size: 25px;
				line-height: 41px;
				margin: 2px 10px 0 0;
			}
		}
		h5 {
			font-weight: 400;
			font-size: 23px;
			line-height: 37px;
		}
		img.profileGrpImg {
			object-fit: cover;
			@media (max-width: 767px) {
				width: 250px;
				height: 250px;
			}
		}
		.incomeBox {
			background-color: #434ed1;
			border-radius: 24px;
			padding: 23px 23px 23px 34px;
			@media (max-width: 1299px) {
				padding: 23px 16px 23px 20px;
			}
			@media (max-width: 1199px) {
				padding: 23px 12px 23px 12px;
			}
			p {
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
			}
			h4 {
				font-weight: 300;
				font-size: 24px;
				line-height: 40px;
				span {
					font-weight: 700;
					font-size: 28px;
					line-height: 45px;
				}
			}
		}
		.amoutBox {
			background-color: #434ed1;
			border-radius: 10px;
			padding: 19px 37px;
			@media (max-width: 899px) {
				padding: 19px 20px;
			}
			h6 {
				color: #fff;
			}
		}
		.amoutBox.callBox {
			border: 1px solid #ffffff;
			background-color: transparent;
			&:hover {
				background-color: #fff;
				h6 {
					color: #3846ce;
				}
			}
		}
	}
`;
