import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import Login from "../components/models/login";
import Register from "../components/models/register";
import { ToastContext } from "./ToastContext";
import axiosClient from "../utils/axiosClient";
import { useNavigate } from "react-router-dom";

type Props = {
	children: ReactNode;
};

export interface AuthContextTypes {
	userState?: UserState;
	isLogin: boolean;
	isLoading: boolean;
	isError: boolean;
	toast: boolean;
	message: string;
	dashboardData: any;
	setDashboardData: Dispatch<any>;
	getProfileDashboardDetails: () => void;
	handleModels: HandleModelsTypes;
	setHandleModels: Dispatch<SetStateAction<HandleModelsTypes>>;
	setUserState: Dispatch<SetStateAction<UserState>>;
	handleLogout: () => void;
	setAuthState: Dispatch<
		SetStateAction<{
			isLogin: boolean;
			isLoading: boolean;
			isError: boolean;
			toast: boolean;
			message: string;
			profileData: profileData;
			callsData: callsDataType;
			callDetails: CallDetailsType;
		}>
	>;
	profileData: profileData;
	callsData: callsDataType;
	callDetails: CallDetailsType;
}

const initState = {
	isLogin: Object.keys(JSON.parse(localStorage.getItem("user") ?? "{}")).length ? true : false,
	isLoading: false,
	isError: false,
	toast: false,
	message: "",
	profileData: {
		name: "",
		outstandingAmount: "",
		todayEarnings: "",
		todayCalls: "",
		totalCalls: "",
		totalSubscribers: "",
		todaySubscribers: "",
		rating: "",
		active: "",
		whatsappNumber: "",
		callerId: "",
		profileImage: "",
	},
	callsData: {
		mycalls: [
			{
				amount: "",
				callExpireTime: "",
				callExpiredTime: "",
				callerCompanyName: "",
				callerId: "",
				callsUpdatedAt: "",
				callstatus: "",
				createdAt: "",
				hostName: "",
				id: "",
				stockExchange: "",
				stopLoss: "",
				target1: "",
				target2: "",
				target3: "",
				tradeCompany: "",
				tradeDetails: "",
				tradeType: "",
				updateCount: "",
				updatedAt: "",
				viewed: "",
			},
		],
		totalPages: 0,
	},
	callDetails: {
		amount: "",
		callExpireTime: "",
		callExpiredTime: "",
		callerCompanyName: "",
		callerId: "",
		callsUpdatedAt: "",
		callstatus: "",
		createdAt: "",
		hostName: "",
		id: "",
		stockExchange: "",
		stopLoss: "",
		target1: "",
		target2: "",
		target3: "",
		tradeCompany: "",
		tradeDetails: "",
		tradeType: "",
		updateCount: "",
		updatedAt: "",
		viewed: "",
		tradeSymbol: "",
		putCall: "",
		buySell: "",
	},
};

export type HandleModelsTypes = {
	for: null | "register" | "login";
	defStep:
	| null
	| "verification"
	| "upload-profile"
	| "business-info"
	| "approval-request"
	| "mobile-verify"
	| "otp-verify";
	state: {
		countryCode: string;
		mobile: string;
		otp: string;
		profile?: string;
		businessName?: string;
		sebiNumber?: string;
		tradeType?: string;
	};
};

const getDefStep = () => {
	const user = JSON.parse(localStorage.getItem("user") || "{}");

	if (user && user.caller && user.caller.active) return "approval-request";
	return "verification";
};

export const initHandleModels: HandleModelsTypes = {
	for: null,
	defStep: getDefStep(),
	state: { countryCode: "+91", mobile: "", otp: "" },
};

export type Caller = {
	aadharUrl: string;
	active: boolean;
	blocked: boolean;
	businessName: string;
	createdAt: string;
	description: string;
	id: number;
	profileImage: string;
	rating: number;
	sebiNumber: string;
	subscriptionId: string;
	telephone: string;
	tradeType: Array<string>;
	updatedAt: string;
	verified: boolean;
};

export type User = {
	blocked: boolean;
	createdAt: string;
	deviceToken: string;
	devices: string;
	id: number;
	name: string;
	otp: string;
	rechargeButton: boolean;
	referralCode: string;
	subscribeButton: boolean;
	telephone: string;
	updatedAt: string;
	upi: string;
	verified: boolean;
};

export type UserState = {
	user?: User;
	caller?: Caller;
	otpVerified: boolean;
	sessionToken: string;
};

export type profileData = {
	name: string;
	outstandingAmount: string;
	todayEarnings: string;
	todayCalls: string;
	totalCalls: string;
	totalSubscribers: string;
	todaySubscribers: string;
	rating: string;
	active: string;
	whatsappNumber: string;
	callerId: string;
	profileImage: string;
};

export type callsDataType = {
	mycalls: {
		amount: string;
		callExpireTime: string;
		callExpiredTime: string;
		callerCompanyName: string;
		callerId: string;
		callsUpdatedAt: string;
		callstatus: string;
		createdAt: string;
		hostName: string;
		id: string;
		stockExchange: string;
		stopLoss: string;
		target1: string;
		target2: string;
		target3: string;
		tradeCompany: string;
		tradeDetails: string;
		tradeType: string;
		updateCount: string;
		updatedAt: string;
		viewed: string;
	}[];
	totalPages: number;
};

export type CallDetailsType = {
	amount: string;
	callExpireTime: string;
	callExpiredTime: string;
	callerCompanyName: string;
	callerId: string;
	callsUpdatedAt: string;
	callstatus: string;
	createdAt: string;
	hostName: string;
	id: string;
	stockExchange: string;
	stopLoss: string;
	target1: string;
	target2: string;
	target3: string;
	tradeCompany: string;
	tradeDetails: string;
	tradeType: string;
	updateCount: string;
	updatedAt: string;
	viewed: string;
	tradeSymbol: string;
	putCall: string;
	buySell: string;
};

export const AuthContext = createContext<AuthContextTypes>({
	handleModels: initHandleModels,
	setHandleModels: () => { },
	setUserState: () => { },
	...initState,
	dashboardData: null,
	getProfileDashboardDetails: () => { },
	setDashboardData: () => { },
	setAuthState: () => { },
	handleLogout: () => { },
});

export const AuthProvider: React.FC<Props> = ({ children }) => {
	const [userState, setUserState] = useState<UserState>(JSON.parse(localStorage.getItem("user") ?? "{}"));
	const [dashboardData, setDashboardData] = useState<any>(null);
	const { notify } = useContext(ToastContext);
	const [{ isLogin, isLoading, isError, toast, message, profileData, callsData, callDetails }, setAuthState] =
		useState<{
			isLogin: boolean;
			isLoading: boolean;
			isError: boolean;
			sessionToken?: string;
			toast: boolean;
			message: string;
			profileData: profileData;
			callsData: callsDataType;
			callDetails: CallDetailsType;
		}>(initState);
	const navigate = useNavigate();
	const [handleModels, setHandleModels] = useState<HandleModelsTypes>(initHandleModels);

	const handleClose = () => {
		setHandleModels(initHandleModels);
	};

	useEffect(() => {
		if (toast)
			notify(message ?? "Something is wrong", {
				type: isError ? "error" : "success",
				onClose: () => {
					setAuthState((state) => ({ ...state, isError: false, toast: false, message: "" }));
				},
			});
	}, [isLoading, isError, message, notify, toast]);

	useEffect(() => {
		if (isLogin && dashboardData && !dashboardData?.active) {
			setHandleModels({ ...handleModels, for: "register", defStep: "approval-request" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin, dashboardData]);

	const getProfileDashboardDetails = async () => {
		try {
			const { data } = await axiosClient.get("/caller/dashboard");
			setDashboardData(data?.result);
			setAuthState((authState) => ({
				...authState,
				isLoading: false,
				profileData: data?.result,
			}));
		} catch (error: any) {
			if (error.request && error.request.status === 401) {
				handleLogout();
			}
			console.log(error);
		}
	};

	const handleLogout = async () => {
		try {
			setAuthState((authState) => ({ ...authState, isLoading: true }));
			const res = await axiosClient.post(`/caller/logout`);
			if (res.status === 200) {
				localStorage.removeItem("user");
				setHandleModels({ ...handleModels, for: null, state: { ...handleModels?.state, mobile: "" } });
				setAuthState((authState) => ({
					...authState,
					isLoading: false,
					toast: true,
					message: "User logout successfully.",
					isLogin: false,
				}));
				navigate("/");
				// setHandleModels({ ...handleModels, for: null });
			}
		} catch (error: any) {
			setAuthState((authState) => ({
				...authState,
				isError: true,
				toast: true,
				message: error?.response?.data?.message ?? error.message,
			}));
		}
	};

	useEffect(() => {
		if (userState?.sessionToken) {
			getProfileDashboardDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogin, userState]);

	return (
		<AuthContext.Provider
			value={{
				toast,
				isError,
				isLogin,
				message,
				userState,
				isLoading,
				callsData,
				profileData,
				callDetails,
				handleModels,
				dashboardData,
				setUserState,
				setAuthState,
				handleLogout,
				setHandleModels,
				setDashboardData,
				getProfileDashboardDetails,
			}}
		>
			{children}
			<Register open={handleModels.for === "register"} defStep={handleModels.defStep} onClose={() => handleClose()} />
			<Login open={handleModels.for === "login"} onClose={() => handleClose()} />
		</AuthContext.Provider>
	);
};