import { Box } from "@mui/material";
import Verification from "./verification";
import UploadProfile from "./uploadProfile";
import BusinessInfo from "./businessInfo";
import ApprovalRequest from "./approvalRequest";
import { RegisterStyle } from "./styled";
import { useEffect, useState } from "react";

type Props = {
	onClose: () => void;
	defStep: any;
	open: boolean;
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: 0,
	borderRadius: "12px",
	boxShadow: 24,
	padding: "15px 15px 33px 15px",
	textAlign: "center",
	overflow: "auto",
	maxHeight: "90vh",
	"@media(max-width: 575px)": {
		width: "88%",
		padding: "15px 10px 30px 10px",
	},
};

const Register: React.FC<Props> = ({ onClose, defStep, open }) => {
	const [step, setStep] = useState<"verification" | "upload-profile" | "business-info" | "approval-request">(
		"verification",
	);

	const handleClose = () => {
		setStep("verification");
		onClose();
	};

	useEffect(() => {
		if (defStep) setStep(defStep);
	}, [defStep]);

	return (
		<RegisterStyle open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box sx={style} width={step === "verification" ? 398 : 508}>
				{step === "verification" && <Verification onClose={handleClose} setStep={setStep} />}
				{step === "upload-profile" && <UploadProfile onClose={handleClose} setStep={setStep} />}
				{step === "business-info" && <BusinessInfo onClose={handleClose} setStep={setStep} />}
				{step === "approval-request" && <ApprovalRequest />}
			</Box>
		</RegisterStyle>
	);
};

export default Register;
