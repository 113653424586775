import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import UnProtectedRoutes from "./route-middleware/unProtectedRoutes";
import ProtectedRoutes from "./route-middleware/protectedRoutes";
import { ToastProvider } from "./context/ToastContext";
import Home from "./pages/home";
import Profile from "./pages/profile";
import Call from "./pages/call";
import CallIdDetail from "./pages/callDetail";

import "./App.css";

function App() {
	const location = useLocation();
	const routeColors: { [key: string]: string } = {
		'/profile': '#3846ce',
		'/call': '#f0f6f9',
		'/call/\\d+': '#f0f6f9'
	};

	useEffect(() => {
		const currentColor = Object.entries(routeColors).find(([route]) => {
			const pathRegexp = new RegExp(`^${route}$`);
			return pathRegexp.test(location.pathname);
		})?.[1] || 'white';
		document.body.style.backgroundColor = currentColor;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<div className="App">
			<ToastProvider>
				<AuthProvider>
					<Routes>
						<Route element={<UnProtectedRoutes />}>
							<Route path="/" element={<Home />} />
						</Route>
						<Route element={<ProtectedRoutes />}>
							<Route path="/profile" element={<Profile />} />
							<Route path="/call" element={<Call />} />
							<Route path="call/:callId" element={<CallIdDetail />} />
						</Route>
					</Routes>
				</AuthProvider>
			</ToastProvider>
		</div>
	);
}

export default App;
