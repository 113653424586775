import OTPInput from "react-otp-input";
import { Box, Button, Link, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import backArrow from "../../../assets/images/backArrow.svg";
import { Dispatch, SetStateAction, useContext } from "react";
import useAuth from "../../../hooks/useAuth";
import axiosClient from "../../../utils/axiosClient";
import { AuthContext, UserState } from "../../../context/AuthContext";

type Props = {
	onClose: () => void;
	setStep: Dispatch<SetStateAction<"verification" | "upload-profile" | "business-info" | "approval-request">>;
};

const Verification: React.FC<Props> = ({ onClose, setStep }) => {
	const { setAuthState } = useContext(AuthContext);
	const { onResendOtp, handleModels, getProfileDashboardDetails, setHandleModels, isLoading } = useAuth();

	const handleLogin = async () => {
		setAuthState((authState: any) => ({ ...authState, isLoading: true }));
		const { data } = await axiosClient.post<{ result: UserState }>(
			`/caller/login?telephone=${encodeURIComponent(
				`${handleModels.state.countryCode}${handleModels.state.mobile}`,
			)}&otp=${handleModels.state.otp}&deviceToken=deviceToken&devices=devices`,
		);
		if (data.result && data.result.sessionToken) {
			onClose();
			localStorage.setItem("user", JSON.stringify(data.result));
			getProfileDashboardDetails();
			setAuthState((authState: any) => ({
				...authState,
				isLoading: false,
				toast: true,
				message: "User login successfully.",
				isLogin: true,
			}));
			return true;
		}
		return false;
	};

	const handleContinueOTP = async () => {
		if (!(await handleLogin()))
			setStep("upload-profile");
		// setHandleModels({ ...handleModels, state: { ...handleModels?.state, otp: "" } });
	};

	return (
		<>
			<Box className="btnClose" mb={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
				<img src={backArrow} alt="back" onClick={onClose} />
				<Close onClick={onClose} />
			</Box>
			<Typography variant="h3" mb={1}>
				Verification
			</Typography>
			<Typography maxWidth={"85%"} mx={"auto"}>
				We have sent verification code to your number
			</Typography>
			<Typography>
				<Link href="">
					({handleModels.state.countryCode}) {handleModels.state.mobile}
				</Link>{" "}
				you used here
			</Typography>
			<Box className="otpSect">
				<OTPInput
					numInputs={5}
					renderSeparator={<span></span>}
					value={handleModels.state.otp}
					onChange={(value: string) => setHandleModels((ps) => ({ ...ps, state: { ...ps.state, otp: value } }))}
					renderInput={(props) => <input name="otp" {...props} />}
					containerStyle={{ justifyContent: "space-between", margin: "12px 0" }}
				/>
			</Box>
			<Typography mt={3} mb={4}>
				Didn't receive code?
				<span className="resendNow" onClick={onResendOtp}>
					Resend Now
				</span>
			</Typography>
			<Button
				variant="contained"
				className="btnPrimary"
				sx={{ width: "88%" }}
				disabled={handleModels.state.otp.length !== 5 || isLoading}
				onClick={handleContinueOTP}
			>
				Continue
			</Button>
		</>
	);
};

export default Verification;
