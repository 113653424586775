import { Box } from "@mui/material";
import MobileVerification from "./mobileVerification";
import OtpVerification from "./otpVerification";
import { RegisterStyle } from "../register/styled";
import { useState } from "react";

type Props = {
	onClose: () => void;
	open: boolean;
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: 0,
	borderRadius: "12px",
	boxShadow: 24,
	padding: "15px 15px 33px 15px",
	textAlign: "center",
	overflow: "auto",
	maxHeight: "90vh",
	"@media(max-width: 575px)": {
		width: "88%",
		padding: "15px 10px 30px 10px",
	},
};

const Login: React.FC<Props> = ({ open, onClose }) => {
	const [steps, setStep] = useState<"mobile-verify" | "otp-verify">("mobile-verify");

	const handleClose = () => {
		setStep("mobile-verify");
		onClose();
	};

	return (
		<RegisterStyle
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style} width={steps === "otp-verify" ? 398 : 508}>
				{steps === "mobile-verify" && <MobileVerification onClose={handleClose} setStep={setStep} />}
				{steps === "otp-verify" && <OtpVerification onClose={handleClose} setStep={setStep} />}
			</Box>
		</RegisterStyle>
	);
};

export default Login;
