import styled from 'styled-components';

export const HomeStyle = styled.div`
    .homeContent{
        padding-top: 118px;
        max-width: 1460px;
        @media(max-width: 899px){
            padding-top: 106px;
        }
        @media(max-width: 599px){
            padding-top: 90px;
        }
        .MuiGrid-container{
            padding: 150px 0;
            @media(max-width: 899px){
                padding: 50px 0 100px 0;
            }
        }
        h5{
            font-weight: 700;
            font-size: 50px;
            line-height: 130%;
            @media(max-width: 1199px){
                font-size: 40px;
            }
            @media(max-width: 599px){
                font-size: 32px;
            }
        }
        p.textSecondary{
            font-size: 24px;
            line-height: 130%;
            margin: 21px 0 38px 0;
            max-width: 65%;
            @media(max-width: 1439px){
                max-width: 80%;
            }
            @media(max-width: 1199px){
                max-width: 90%;
            }
            @media(max-width: 899px){
                margin: 21px auto 38px auto;
            }
            @media(max-width: 767px){
                max-width: 100%;
                font-size: 20px;
            }
        }
        button.btnPrimary{
            padding: 10px 19px;
            @media(max-width: 599px){
                display: block;
                margin: 20px auto 0 auto;  
            }
        }
        .MuiFormControl-root{
            .MuiOutlinedInput-root{
                color: #818181;
                border-radius: 8px; 
                height: 47px;
                input::placeholder{
                    opacity: 1;
                }
                fieldset{
                    border-color: #3846CE;
                }
            }
            .MuiInputAdornment-root p{
                font-weight: 700;
                color: #000;
            }
        }
        h4{
            font-weight: 400;
            font-size: 12px;
        }
        img{
            @media(max-width: 1199px){
                width: 100%;
            }
            @media(max-width: 899px){
                width: 70%;
            }
            @media(max-width: 599px){
                width: 90%;
            }
        }
    }
`;