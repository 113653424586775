import styled from 'styled-components';

export const HeaderStyle = styled.div`
    .MuiAppBar-root{
        background-color: #fff;
        box-shadow: none;
        .MuiContainer-root{
            max-width: 1650px;
        }
        .MuiToolbar-root{
            padding: 21px 0;
            @media(max-width: 599px){
                min-height: 48px;
            }
        }
        .MuiTypography-h6{
            a{
                font-weight: 700;
                font-size: 48px;
                @media(max-width: 899px){
                    font-size: 36px;
                }
                @media(max-width: 599px){
                    font-size: 28px;
                }
            }
        }
        a{
            font-weight: 700;
            font-size: 20px;
            text-transform: capitalize;
            margin-right: 85px;
            @media(max-width: 899px){
                margin-right: 35px;
                font-size: 18px;
            }
            :hover{
                color: #3846CE !important;
            }
        }
        .menuIcon{
            margin-left: 0;
        }
    }
`;