import { Box, Button, Container, FormControl, Grid, InputAdornment, Typography, TextField } from "@mui/material";
import Layout from "../../layout";
import trade from "../../assets/images/trade.svg";
import { HomeStyle } from "./styled";
import useAuth from "../../hooks/useAuth";

const Home = () => {
	const { isLogin, handleModels, onNumberChangeForRegister, handleSendRegisterOtp } = useAuth();
	return (
		<Layout>
			<HomeStyle>
				<Container className="homeContent">
					<Grid container alignItems={"center"}>
						<Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} textAlign={{ xs: "center", md: "left" }}>
							<Typography variant="h5" className="textPrimary">
								Invest in everything
							</Typography>
							<Typography className="textSecondary">
								Online platform to invest in stocks, derivatives, mutual funds, and more
							</Typography>
							{!isLogin ? (
								<>
									<FormControl sx={{ mr: 1, width: "27ch" }} variant="outlined">
										<TextField
											autoFocus={true}
											id="outlined-adornment-weight"
											type="number"
											name="telephone"
											value={handleModels?.state.mobile}
											placeholder="Enter your number"
											onChange={onNumberChangeForRegister}
											// inputProps={{
											// 	maxLength: 10,
											// }}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">{handleModels.state.countryCode}</InputAdornment>
												),
											}}
										/>
									</FormControl>
									<Button
										variant="contained"
										className="btnPrimary"
										disabled={handleModels.state.mobile.length !== 10}
										onClick={() => handleSendRegisterOtp()}
									>
										Register
									</Button>
								</>
							) : (
								""
							)}
						</Grid>
						<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
							<Box textAlign={{ xs: "center", md: "right" }} mb={{ xs: 10, md: 0 }} ml={3}>
								<img src={trade} alt="trade" />
							</Box>
						</Grid>
					</Grid>
				</Container>
			</HomeStyle>
		</Layout>
	);
};

export default Home;
