import { Modal } from '@mui/material';
import styled from 'styled-components';

export const RegisterStyle = styled(Modal)`
    .btnClose{
        svg{
            font-size: 18px;
            color: #7691BB;
            cursor: pointer;
        }
        img{
            cursor: pointer;
        }
    }
    h3{
        color: #191D32;
        font-weight: 700;
        font-size: 24px;
    }
    p{
        color: #929AA3;
        line-height: 26px;
    }
    .otpSect{
        margin: 38px 10px 0 10px;
        input{
            width: 62px !important;
            height: 67px;
            background: #F0F6F9;
            border-radius: 8px; 
            border: 1px solid #C5DFEB;
            padding: 0;
            color: #191D32;
            font-size: 16px;
            font-weight: 300;
            :focus-visible{
                outline: none;
            }
            @media (max-width: 460px){
                width: 45px !important;
                height: 45px;
            }
        }
    }
    p span.resendNow{
        color: #3846ce;
        font-weight: 300;
        cursor: pointer;
    }
    .profileSect{
        width: 241px;
        height: 241px;
        position: relative;
        margin: 50px auto;
        .profileImg{
            position: absolute;
            width: 195px;
            height: 195px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
        }
        .MuiButtonBase-root{
            position: absolute;
            bottom: -18px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
        }
    }
    .MuiOutlinedInput-root{
        background: #F0F6F9;
        color: #191D32;
        border-radius: 11px;
        height: 62px;
        input{
            padding-left: 25px;
            ::placeholder{
                opacity: 1;
            }
        }
        fieldset{
            border: 0;
        }
    }
    .regNumField .MuiOutlinedInput-root{
        color: #818181;
    }
    h4{
        color: #191D32;
        font-weight: 700;
        font-size: 20px;
    }
    h5{
        color: #11192B;
        font-weight: 300;
        font-size: 16px;
    }
`;