import { Box, Button, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import camera from "../../../assets/images/camera.svg";
import outline from "../../../assets/images/outline.svg";
import profile from "../../../assets/images/profile.png";
import backArrow from "../../../assets/images/backArrow.svg";
import { Dispatch, SetStateAction } from "react";
import useAuth from "../../../hooks/useAuth";

type Props = {
	onClose: () => void;
	setStep: Dispatch<SetStateAction<"verification" | "upload-profile" | "business-info" | "approval-request">>;
};

const UploadProfile: React.FC<Props> = ({ onClose, setStep }) => {
	const { handleModels, handleFileInputChange } = useAuth();

	const handleNext = () => setStep("business-info");

	return (
		<>
			<Box className="btnClose" mb={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
				<img src={backArrow} alt="back" onClick={() => setStep("verification")} />
				<Close onClick={onClose} />
			</Box>
			<Typography variant="h3" mb={1}>
				Upload Profile Picture
			</Typography>
			<Box className="profileSect">
				<img src={outline} width={"100%"} height={"100%"} alt="outline" />
				<img
					src={handleModels.state.profile ?? profile}
					width={"195px"}
					height={"195px"}
					alt="profile"
					className="profileImg"
				/>
				<IconButton color="primary" aria-label="upload picture" component="label">
					<input hidden accept="image/*" type="file" name="profileImage" onChange={handleFileInputChange} />
					<img src={camera} alt="camera" />
				</IconButton>
			</Box>
			<Button
				variant="contained"
				className="btnPrimary"
				disabled={!handleModels.state.profile}
				sx={{ width: "88%" }}
				onClick={handleNext}
			>
				Next
			</Button>
		</>
	);
};

export default UploadProfile;
