import { Dispatch, SetStateAction } from "react";
import OTPInput from "react-otp-input";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import backArrow from "../../../assets/images/backArrow.svg";
import useAuth from "../../../hooks/useAuth";
type Props = {
	onClose: () => void;
	setStep: Dispatch<SetStateAction<"mobile-verify" | "otp-verify">>;
};

const OtpVerification: React.FC<Props> = ({ onClose, setStep }) => {
	const { handleModels, setHandleModels, onResendOtp, handleLogin, isLoading } = useAuth();

	const handleChange = (value: string) => {
		setHandleModels({ ...handleModels, state: { ...handleModels.state, otp: value } });
	};

	return (
		<>
			<Box className="btnClose" mb={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
				<img src={backArrow} alt="back" onClick={() => setStep("mobile-verify")} />
				<Close onClick={onClose} />
			</Box>
			<Typography variant="h3" mb={1}>
				Verification
			</Typography>
			<Typography maxWidth={"85%"} mx={"auto"}>
				We have sent verification code to your number
			</Typography>
			<Typography>{/* <Link href="">(+91) {handleModels.state.mobile}</Link> you used here */}</Typography>
			<Box className="otpSect">
				<OTPInput
					numInputs={5}
					renderSeparator={<span></span>}
					value={handleModels?.state.otp}
					onChange={handleChange}
					renderInput={(props) => <input name="otp" {...props} />}
					containerStyle={{ justifyContent: "space-between", margin: "12px 0" }}
				/>
			</Box>
			<Typography mt={3} mb={4}>
				Didn't receive code?{" "}
				<span className="resendNow" onClick={onResendOtp}>
					{" "}
					Resend Now
				</span>
			</Typography>
			<Button
				variant="contained"
				className="btnPrimary"
				sx={{ width: "88%" }}
				onClick={() => handleLogin(setStep, onClose)}
				disabled={!(handleModels?.state?.otp?.length >= 5) || isLoading}
			>
				Login
			</Button>
		</>
	);
};

export default OtpVerification;
