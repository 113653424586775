import * as Yup from "yup";

export const crateCallValidationSchema = Yup.object().shape({
	amount: Yup.number().default(0),
	callerId: Yup.number().required("CallerId is required").nullable(),
	stockExchange: Yup.string().required("StockExchange is required"),
	tradeType: Yup.string().optional(),
	callerCompanyName: Yup.string().required("Trade Type is required"),
	tradeCompany: Yup.string().required("Trade Company is required"),
	tradeSymbol: Yup.string().required("Trade Symbol is required"),
	// callExpiredTime: Yup.date().required("Expired Date is required"),
	tradeDetails: Yup.string().required("Trade Details is required"),
	target1: Yup.string().required("Target1 is required"),
	target2: Yup.string().required("Target2 is required").test('target-2 is valid', 'target-2 is grater than target-1', function (value) {
		const target1Value: any = this.resolve(Yup.ref('target1'));
		if (!value) {
			return false;
		}
		return Number(value) > Number(target1Value);
	}),
	target3: Yup.string().required("Target3 is required").test('target-3 is valid', 'target-3 value is grater than target-2', function (value) {
		const Target2Value: any = this.resolve(Yup.ref('target2'));
		if (!value) {
			return false;
		}
		return Number(value) > Number(Target2Value);
	}),
	stopLoss: Yup.string().required("StopLoss is required").test('stoploss is valid', 'stoploss is less than target-1', function (value) {
		const Target1Value: any = this.resolve(Yup.ref('target1'))
		if (!value) {
			return false
		}
		return Number(value) < Number(Target1Value)
	}),
});



export const UpdateCallValidationSchema = Yup.object().shape({
	stockExchange: Yup.string().required("StockExchange is required"),
	tradeType: Yup.string().required("Trade Type is required"),
	tradeCompany: Yup.string().required("Trade Company is required"),
	tradeDetails: Yup.string().required("Trade Details is required"),
	target1: Yup.string().required("Target1 is required"),
	target2: Yup.string().required("Target2 is required").test('target-2 is valid', 'target-2 is grater than target-1', function (value) {
		const target1Value: any = this.resolve(Yup.ref('target1'));
		if (!value) {
			return false;
		}
		return Number(value) > Number(target1Value);
	}),
	target3: Yup.string().required("Target3 is required").test('target-3 is valid', 'target-3 value is grater than target-2', function (value) {
		const Target2Value: any = this.resolve(Yup.ref('target2'));
		if (!value) {
			return false;
		}
		return Number(value) > Number(Target2Value);
	}),
	stopLoss: Yup.string().required("StopLoss is required").test('stoploss is valid', 'stoploss is less than target-1', function (value) {
		const Target1Value: any = this.resolve(Yup.ref('target1'))
		if (!value) {
			return false
		}
		return Number(value) < Number(Target1Value)
	}),
});
